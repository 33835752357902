<template>
  <b-container fluid>
    <b-card class="mt-3" v-if="!tokenValidated">
      <b-col class="text-center"> </b-col>
      <b-card-body id="SignUp" v-if="!submited" class="pt-3">
        <b-form>
          <b-row>
            <b-col class="text-center">
              <strong> Bienvenido! </strong>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center pt-3 pb-3 icon-token">
              <!-- <img src="@/assets/id-badge-solid.svg" width="92px" /> -->
              <welcome :color="getMainColor" />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="text-center">
              Si ya recibió su código de invitación, por favor ingréselo. En caso de no tener su invitación puede solicitarla a continuación
            </b-col>
          </b-row>
          <b-row class="pt-4">
            <b-col>
              <b-form-group
                id="input-group-4"
                :description="tokenDesc"
                label-for="signup-4"
                class="token-input"
              >
              <b-row align-v="center">
                <b-col cols="6">
                  <b-form-input
                    id="signup-4"
                    placeholder="Código de invitación"
                    autocomplete="off"
                    type="text"
                    v-model="$v.form.data.token.$model"
                    :state="tokenState"
                    required

                  ></b-form-input>
                </b-col>
                <b-col cols="6 text-center">
                  <router-link class="token-link" to="ValidateToken">Solicitar invitación</router-link>
                </b-col>
              </b-row>
              </b-form-group>
            </b-col>  
          </b-row>
          <b-row class="pt-2">
            <b-col>
              <b-button
                  class="align-items-center"
                  block
                  v-b-tooltip.hover
                  @click.prevent="validateUserToken"
                  size="lg"
                  variant="primary"
                  :disabled="
                    !$v.$anyDirty ||
                    $v.$anyError ||
                    this.loading ||
                    this.form.data.token == '' ||
                    this.tokenValidated
                  "
                >
                  <span v-if="!this.loading" class="mb-2 form-text">VALIDAR CÓDIGO DE INVITACIÓN</span>
                  <b-spinner type="grow" v-else label="Loading..."></b-spinner>
                </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card-body>
    </b-card>
    <b-card  v-else class="mt-3" :class="[!tokenValidated ? 'disabled-card' : '']">
      <b-col class="text-center"> </b-col>
      <b-card-body id="SignUp" v-if="!submited" class="pt-3">
        <b-row>
          <b-col class="text-center">
            <strong> Cree su contraseña </strong>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <!-- <svg style="width: 92px; height: 92px" viewBox="0 0 24 24">
              <path
                fill="#005D7B"
                d="M23.5 17L18.5 22L15 18.5L16.5 17L18.5 19L22 15.5L23.5 17M13 18H3V8L11 13L19 8V13H21V6C21 4.9 20.1 4 19 4H3C1.9 4 1 4.9 1 6V18C1 19.1 1.9 20 3 20H13V18M19 6L11 11L3 6H19Z"
              />
            </svg> -->
            <mail-icon :color="getMainColor" />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            Su contraseña debe tener al menos 8 caracteres, una letra, un número y un caracter especial
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="signup">
              <b-form>
                <b-form-group
                  id="input-group-1"
                  label-for="signup-1"
                  :description="emailDesc"
                >
                  <b-form-input
                    :state="emailState"
                    id="signup-1"
                    type="email"
                    v-model.lazy="$v.form.data.email.$model"
                    placeholder="Su email"
                    required
                    :disabled="!tokenValidated"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  :description="password1Desc"
                  label-for="signup-2"
                >
                  <b-form-input
                    @keyup.enter.native="doLogin"
                    id="signup-2"
                    placeholder="Ingresar contraseña"
                    type="password"
                    autocomplete="off"
                    v-model="$v.form.data.password1.$model"
                    :state="password1State"
                    required
                    :disabled="!tokenValidated"
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-3"
                  :description="password2Desc"
                  label-for="signup-3"
                >
                  <b-form-input
                    id="signup-3"
                    placeholder="Repetir contraseña"
                    autocomplete="off"
                    type="password"
                    v-model="$v.form.data.password2.$model"
                    :state="password1State"
                    required
                    :disabled="!tokenValidated"
                  ></b-form-input>
                </b-form-group>

                <b-button
                  class="align-items-center"
                  block
                  v-b-tooltip.hover
                  title="Siguiente"
                  @click.prevent="confirmStep"
                  size="lg"
                  variant="primary"
                  :disabled="
                    !$v.$anyDirty ||
                    $v.$anyError ||
                    this.loading ||
                    this.form.data.password2 == ''
                  "
                >
                  <span v-if="!this.loading" class="mb-2 form-text">SIGUIENTE</span>
                  <b-spinner type="grow" v-else label="Loading..."></b-spinner>
                </b-button>
              </b-form>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <stepper class="stepper" :filled="currentStep" :size="maxSteps"/>
        </b-row>
      </b-card-body>
      <b-card-body id="ActivateOk" v-else class="pt-3">
        <b-row>
          <b-col class="text-center text-secondary" variant="secondary">
            
            <mail-icon :color="getMainColor" />
          </b-col>
        </b-row>
        <b-row>
          <b-col class="text-center">
            <p>
              Hola <strong>{{ this.form.email }}</strong
              >, te enviamos un mail para activar tu cuenta
            </p>
            <p>Por favor verifica tu correo</p>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-container>
</template>

<script>
import { required, sameAs, email } from "vuelidate/lib/validators"
import userService from '@/services/userService'
import { mapActions, mapGetters } from 'vuex'
import stepsMixin from '@/mixins/stepsMixin'

export default {
  name: "SignUp",
  components: {
    Stepper: () => import('@/views/Components/Giftcards/Stepper'),
    MailIcon: () => import('@/components/icons/mail'),
    Welcome: () => import('@/components/icons/welcome')
  },
  mixins: [stepsMixin],
  data: () => {
    return {
      submited: false,
      loading: false,
      form: {
        data: { email: "", password1: "", password2: "", token: "" },
      },
      tokenValidated: false
    }
  },

  methods: {
    errorNotify(msg) {
      const notification = {
        message: msg,
        variant: "danger",
        countdown: 5,
      }
      this.$store.dispatch("notifications/notify", {
        notification: notification,
      })
    },
    successNotify(msg) {
      const notification = {
        message: msg,
        variant: "success",
        countdown: 5,
      }
      this.$store.dispatch("notifications/notify", {
        notification: notification,
      })
    },    
    async confirmStep() {
      console.log(this.$v.form.data.email.$model)
      this.setEmail({ email: this.$v.form.data.email.$model })
      this.$emit("confirm-step", this.form)
    },
    validateUserToken() {
      this.loading = true
      userService.ValidateToken(this.form.data.token)
        .then(resp => {
          if (resp.data.error !== 0) {
            this.errorNotify(resp.data.message)
          } else {
            this.tokenValidated = true
            this.successNotify('Token verificado. Puede continuar con el registro.')
          }
        })
        .catch(err => console.log(err))
        .finally(() => {this.loading=false})      
    },
    ...mapActions('user', [
      'setEmail'
    ])
  },

  computed: {
    ...mapGetters('user', [
      'getMainColor'
    ]),
    emailDesc: function () {
      return !this.emailState ? "Ingrese un email válido" : ""
    },
    emailState: function () {
      return !this.$v.form.data.email.$invalid && this.$v.form.data.email.$dirty
    },
    password1Desc: function () {
      const valid =
        this.$v.form.data.password1.strongPassword &&
        this.$v.form.data.password1.$dirty
      const passDesc = "La contraseña no cumple con lo requerido"
      return !valid ? passDesc : ""
    },
    password1State: function () {
      return this.password1Desc === "" ? true : null
    },
    password2Desc: function () {
      const valid =
        this.$v.form.data.password2.sameAsPassword &&
        this.$v.form.data.password2.$dirty
      return !valid ? "Las contraseñas deben ser iguales" : ""
    },
    password2State: function () {
      return this.password2Desc === "" ? true : null
    },
    tokenDesc: function() {
      return !this.tokenState || this.$v.form.data.token.$anyError ? "Validar código de invitación" : ""
    },
    tokenState: function() {
      return this.$v.form.data.token.$model !== '' 
    }
  },

  validations: {
    form: {
      data: {
        email: {
          required,
          email,
        },
        password1: {
          required,
          strongPassword(password1) {
            return (
              /[a-z]/.test(password1) && // checks for a-z
              /[0-9]/.test(password1) && // checks for 0-9
              /\W|_/.test(password1) && // checks for special char
              password1.length >= 6
            )
          },
        },
        password2: {
          required,
          sameAsPassword: sameAs("password1"),
        },
        token: {
          required
        }
      },
    },
  },
}
</script>

<style lang="css" scoped>
.signup {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 5% 0;
}

.copyright {
  font-size: 0.8rem;
}

.disabled-card {
  background-color: #331a7610;
}
</style>

